/* eslint-disable tailwindcss/no-custom-classname */

'use client';

import 'keen-slider/keen-slider.min.css';

import { useResponsiveQuery } from 'atomic-layout';
import { useKeenSlider } from 'keen-slider/react';
import Image from 'next/image';
import React, { useState } from 'react';

import Icon from '@/components/v3/Icon';
import { MarkdownContent } from '@/components/v4/MarkdownContent';
import { TestimonialsProps } from '@/types/cms';

import styles from './Testimonials.module.css';

const Testimonials: React.FC<TestimonialsProps> = ({
  referId,
  title,
  testimonialsCard,
  assessment,
}) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const isMobile = useResponsiveQuery({ to: 'md' });

  const [keenSliderRef, keenSlider] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 3,
      spacing: 24,
    },
    initial: 0,
    mode: 'snap',
    renderMode: 'performance',
    loop: false,
    breakpoints: {
      '(max-width: 1024px)': {
        slides: {
          perView: 3,
          spacing: 24,
        },
      },
      '(max-width: 800px)': {
        slides: {
          perView: 2.2,
          spacing: 24,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1.2,
          spacing: 16,
        },
      },
    },
    slideChanged(s) {
      setCurrentPosition(Math.round(s.track.details.rel));
    },
  });

  const prevArrowDisable = currentPosition < 1;
  const nextArrowDisable =
    currentPosition === keenSlider.current?.track.details.maxIdx;

  return (
    <div className="flex flex-col items-center mx-auto max-w-[1328px]">
      <div
        className="flex flex-col gap-24 lg:gap-40 p-16 py-40 lg:py-80 px-24 w-full"
        id={referId}
      >
        <div className="flex flex-col gap-24 lg:gap-40 justify-center items-center mx-auto w-full md:max-w-[1280px] bg-display-0">
          <div className="flex flex-col md:flex-row gap-24 md:gap-24 justify-center md:justify-between items-center md:items-stretch w-full">
            <h3 className="md:max-w-[319px] lg:max-w-[375px] font-display text-3xl lg:text-4xl font-bold leading-7 lg:leading-8">
              {title}
            </h3>
            <div className="flex gap-24 lg:gap-40 justify-center items-center py-16 px-24 w-[316px] md:w-[416px] rounded-md border-2 border-display-200 solid">
              {assessment.imageRepetable?.map(({ image }) => (
                <Image
                  src={image.path}
                  alt={image.alternativeText}
                  width={isMobile ? 72 : 94}
                  height={isMobile ? 84 : 90}
                />
              ))}
            </div>
          </div>
          <div
            className={`flex flex-wrap gap-24 lg:gap-40 gap-x-16 justify-center w-full max-w-[1280px] h-full`}
          >
            <div
              ref={keenSliderRef}
              className={`flex max-w-full keen-slider pr-24 ${styles.sliderContainer}`}
            >
              {testimonialsCard.map(({ image, name, testimonial }) => (
                <div
                  // eslint-disable-next-line tailwindcss/no-custom-classname
                  className="flex flex-col gap-16 p-24 bg-display-100 rounded-md keen-slider__slide"
                  key={name}
                >
                  <div className="flex justify-between">
                    <p className="font-semibold paragraph-18">{name}</p>
                    <div className="overflow-hidden justify-center items-center w-[78px] min-w-[78px] h-16">
                      <Image
                        src={image.path}
                        alt={image.alternativeText}
                        width={78}
                        height={16}
                        className="object-contain justify-center items-center"
                        layout="responsive"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <MarkdownContent
                      content={testimonial}
                      className="mt-auto paragraph-16"
                    />
                  </div>
                </div>
              ))}
            </div>
            {keenSlider && (
              <div className="flex gap-12 justify-center items-center">
                <button
                  type="button"
                  title="botão anterior"
                  className={`flex justify-center items-center w-40 h-40 bg-display-200 border-0 border-display-0 border-solid ${
                    !prevArrowDisable &&
                    'hover:bg-display-300 focus:border-2 active:border-2 focus:border-stone-200 active:border-stone-200'
                  } rounded-xs`}
                  onClick={() => {
                    keenSlider.current?.prev();
                  }}
                  disabled={prevArrowDisable}
                >
                  <Icon
                    name="chevron-left"
                    className={`w-20 h-20 font-bold fill-current ${
                      prevArrowDisable
                        ? 'text-display-400'
                        : 'text-display-700 hover:text-display-800'
                    }`}
                  />
                </button>
                <button
                  type="button"
                  title="botão avançar"
                  className={`flex justify-center items-center w-40 h-40 bg-display-200 border-0 border-display-0 border-solid ${
                    !nextArrowDisable &&
                    'hover:bg-display-300 focus:border-2 active:border-2 focus:border-stone-200 active:border-stone-200'
                  } rounded-xs`}
                  onClick={() => {
                    keenSlider.current?.next();
                  }}
                  disabled={nextArrowDisable}
                >
                  <Icon
                    name="chevron-right"
                    className={`w-20 h-20 font-bold fill-current ${
                      nextArrowDisable
                        ? 'text-display-400'
                        : 'text-display-700 hover:text-display-800'
                    }`}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
