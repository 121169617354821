import Link from 'next/link';
import React from 'react';

import Icon from '@/components/v3/Icon';
import { FeaturesProps as FeaturesIconProps } from '@/types/cms';

const Features: React.FC<FeaturesIconProps> = ({ referId, cards }) => {
  return (
    <div
      id={referId}
      className="flex justify-center items-center self-stretch px-8 md:px-24 lg:px-24 2xl:px-0 pt-24 md:pt-40 mx-auto max-w-[1280px]"
    >
      <div className="flex flex-wrap xl:flex-1 gap-16 lg:gap-40 px-16 md:px-0 md:pt-0 w-full md:max-w-screen-xl">
        {cards.map(({ title, icon, description, link }, index) => (
          <div
            key={`feature-${title}`}
            className="flex flex-row md:flex-row md:flex-1 gap-40 md:gap-8 lg:gap-40 pb-16 lg:pb-24 mx-auto w-full md:w-min md:max-w-none hover:lg:text-stone-500 lg:text-display-500 border-b-2 lg:border-b-[3px] border-b-display-300 hover:border-b-stone-500 lg:border-b-display-200 border-solid cursor-pointer"
          >
            <Link
              key={index}
              href={{
                pathname: link ? link.href : '',
                hash: link ? link.hash : '',
              }}
              aria-label={`Link para a sessão ${title}`}
              className="w-full"
            >
              <div className="flex w-full">
                <div className="w-full">
                  <div className="flex flex-row">
                    <h5 className="w-full min-w-full lg:min-w-[245px] lg:max-w-[400px] font-display font-bold lg:!font-medium heading-5 md:heading-6 lg:heading-5">
                      {title}
                    </h5>
                    <Icon
                      name={icon.name}
                      className="hidden lg:block gap-8 py-2 my-2 w-[24px] h-[24px] lg:text-inherit fill-current"
                    />
                  </div>
                  <p className="font-normal paragraph-18 lg:paragraph-20">
                    {description}
                  </p>
                </div>
                <Icon
                  name={icon.name}
                  className="block lg:hidden gap-8 self-center lg:self-stretch py-2 w-[24px] h-[24px] text-stone-500 fill-current"
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
