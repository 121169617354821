'use client';

import React, { useContext, useEffect, useState } from 'react';
import LINKS from '@/constants/links';
import ButtonOpenYourAccount from '@/components/ButtonOpenYourAccount';
import { AmplitudeContext } from '@/contexts/amplitude';

const FixedButton = ({
  qrCodeLink = LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ_BUTTON_FLOAT,
}: {
  qrCodeLink?: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const shouldShowButton = window.scrollY > 480;
    setIsVisible(shouldShowButton);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { analytics } = useContext(AmplitudeContext);

  return (
    <div
      className={`${
        isVisible ? 'flex' : 'hidden'
      } md:hidden fixed bottom-0 z-[50] px-24 py-16 w-full bg-display-0`}
    >
      <ButtonOpenYourAccount
        className="w-full"
        qrCodeLink={qrCodeLink}
        onClick={() =>
          analytics?.track({
            event_type: 'cta stone',
            event_properties: {
              name: 'cta stone',
              description:
                'Evento disparado quando o usuário clicar nos ctas da LP',
              cta_reference: 'Floating Button Mobile',
            },
          })
        }
      >
        Abra sua Conta PJ Stone
      </ButtonOpenYourAccount>
    </div>
  );
};

export default FixedButton;
