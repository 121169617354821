'use client';

import { useResponsiveQuery } from 'atomic-layout';
import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { AmplitudeContext } from '@/contexts/amplitude';

import { listprops } from './mock';

const ComparativeListOld = () => {
  const { referId, title, items } = listprops;
  const isTablet = useResponsiveQuery({ from: 'md' });
  const [activeTab, setActiveTab] = useState(0);

  const defaultStyle =
    'flex justify-center items-center ld:w-[283px] md:w-[240px] h-[58px]';

  const { analytics } = useContext(AmplitudeContext);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina',
          section_reference:
            'Conheça as principais condições da Conta PJ Stone',
        },
      });
    }
  }, [analytics, inView]);

  return (
    <div
      id={referId}
      ref={ref}
      className="flex flex-col justify-center py-40 md:py-80 w-full"
    >
      <div className="px-24">
        <h2 className="md:w-full min-w-[312px] font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-9 text-center">
          {title}
        </h2>
        <p className="mt-8 text-xl leading-5 text-center">
          Confira aqui a{' '}
          <a
            onClick={() => {
              analytics?.track({
                event_type: 'cta stone',
                event_properties: {
                  name: 'cta stone',
                  description:
                    'Evento disparado quando o usuário clicar nos ctas da LP',
                  section_reference:
                    'Conheça as principais condições da Conta PJ Stone',
                  cta_reference: 'Taxas e Tarifas ',
                },
              });
            }}
            className="text-xl font-semibold leading-5 text-center text-stone-500"
            href="https://res.cloudinary.com/dunz5zfpt/image/upload/v1707506704/stone-cms/prod/Tabela_de_Tarifas_e_Taxas_Retificacao_1_1178efdcb4.pdf?utm_source=google&utm_medium=search&utm_campaign=ac_gsn_brand_ID_6458670873&utm_content=stone&gclid=CjwKCAiA29auBhBxEiwAnKcSqjQobDdrEVXFIf7rF6YgFjA9zjrX8pDaGcmE6gFINIFtkBafCnb2uhoCz9EQAvD_BwE"
            target="_blank"
            rel="noreferrer"
          >
            tabela de taxas e tarifas
          </a>
        </p>
      </div>

      {isTablet ? (
        <div className="flex justify-center mt-40 w-full">
          <div>
            <div className="flex justify-start items-center pl-16 h-[58px]" />
            <div className="rounded-l-[24px] border-[1px] border-display-300">
              {items.header.map(({ nameList }, index) => (
                <div
                  className={`flex justify-start items-center pl-16 md:w-[240px] lg:w-[494px] h-[58px] ${
                    index === items.header.length - 1
                      ? ''
                      : 'border-b-[1px] border-b-display-300'
                  }`}
                  key={index}
                >
                  <p className="max-w-[208px] lg:max-w-full font-semibold">
                    {nameList}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {items.list.map(({ nameList, item }, index) =>
            index === 0 ? (
              <div className="flex flex-col bg-stone-50 rounded-[24px] border-2 border-stone-500">
                <div
                  className={`${defaultStyle} border-b-[1px] border-b-display-300`}
                >
                  <p className="font-sans text-lg font-bold">{nameList}</p>
                </div>
                {item.map(({ value }, index) => {
                  return (
                    <div
                      className={`${defaultStyle} border-b-[1px] border-b-display-300 ${
                        index === item.length - 1 ? 'mb-24' : ''
                      }`}
                      key={index}
                    >
                      <p className="font-sans text-lg font-bold">{value}</p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <div className={defaultStyle}>{nameList}</div>

                <div
                  className={`${
                    items.list.length - 1 === index && 'rounded-r-[24px]'
                  } border-[1px] border-display-300`}
                >
                  {item.map(({ value }, index) => {
                    return (
                      <div
                        className={`${defaultStyle} ${
                          index === item.length - 1
                            ? 'border-b-0'
                            : 'border-b-[1px] border-b-display-300'
                        }`}
                        key={index}
                      >
                        {value}
                      </div>
                    );
                  })}
                </div>
              </div>
            ),
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center px-16 mt-24 w-full">
          <div className="flex gap-8 justify-center mb-24 w-full">
            {items.header.map(({ nameListMobile }, index) => (
              <div
                className={`flex w-auto p-8 justify-center items-center cursor-pointer ${
                  activeTab === index && 'border-b-2 border-b-stone-500'
                }`}
                onClick={() => setActiveTab(index)}
                key={index}
              >
                <p className="font-sans text-base font-semibold leading-4">
                  {nameListMobile}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-center items-center">
            {items.list.map(({ nameList, item }, index) =>
              index === 0 ? (
                <div className="flex justify-between py-[26px] px-32 w-full min-w-[328px] max-w-[484px] font-semibold bg-stone-50 rounded-base border-2 border-stone-500">
                  <p className="text-lg font-semibold">{nameList}</p>
                  <p className="text-lg font-semibold">
                    {item[activeTab].value}
                  </p>
                </div>
              ) : (
                <div
                  className={`flex justify-between py-[26px] px-16 w-[296px] ${
                    items.list.length - 1 === index && 'rounded-b-base'
                  } border-2 border-t-0 border-display-300`}
                >
                  <p className="text-lg">{nameList}</p>
                  <p className="text-lg">{item[activeTab].value}</p>
                </div>
              ),
            )}
            {items.header[activeTab].description && (
              <p className="mt-16 text-center">
                {items.header[activeTab].description}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparativeListOld;
