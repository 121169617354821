'use client';

import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import LINKS from '@/constants/links';
import ButtonOpenYourAccount from '@/components/ButtonOpenYourAccount';
import Switch from '@/components/Switch';
import { For } from '@/components/UtilitiesComponents';
import { AmplitudeContext } from '@/contexts/amplitude';
import { ComparativeListProps } from '@/types/cms/conta-pj';

import HeroModal from '../HeroNewOffer/components/HeroModal';
import {
  getItemClasses,
  getItemClassesActive,
  getItemClassesBanks,
} from './table';

interface List extends ComparativeListProps {
  qrCodeLink?: string;
}
const ComparativeList: React.FC<List> = ({
  referId,
  title,
  items,
  qrCodeLink = LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ,
}) => {
  const { analytics } = useContext(AmplitudeContext);
  const [ref, inView] = useInView({ triggerOnce: true });
  const [offerState, setOfferState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const listLength = items.length;

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina',
          section_reference:
            'Conheça as principais condições da Conta PJ Stone',
        },
      });
    }
  }, [analytics, inView]);

  return (
    <>
      <HeroModal showModal={showModal} closeModal={() => setShowModal(false)} />
      <div
        id={referId}
        ref={ref}
        className="flex flex-col justify-center py-40 md:py-80 w-full"
      >
        <div className="px-24">
          <h2 className="md:w-full min-w-[312px] font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-9 text-center">
            {title}
          </h2>
          <p className="mt-8 text-xl leading-5 text-center">
            Com ofertas válidas por tempo limitado pra novos clientes com oferta
            ativa na Conta PJ Stone.{' '}
            <span
              onClick={() => {
                setShowModal(true);
              }}
              className="text-xl leading-5 text-center text-stone-500 underline cursor-pointer"
            >
              Confira mais detalhes
            </span>
          </p>
        </div>
        <div className="md:flex md:justify-center mx-16 mt-[40px]">
          <div className="flex overflow-x-auto w-auto md:w-[900px] lg:w-[1065px]">
            <div className="w-full min-w-[469px] max-w-[1065px]">
              <For
                of={items || []}
                render={(item, index) => (
                  <div
                    key={index}
                    className={`grid lg:grid-cols-4  grid-cols-3`}
                  >
                    <div
                      className={`${getItemClasses(
                        index,
                        listLength,
                      )} text-lg font-medium leading-[26px] lg:col-span-2 flex items-center`}
                    >
                      {item.name}
                    </div>
                    <div
                      className={`${getItemClassesActive(index, listLength)} ${
                        offerState ? 'bg-display-100' : 'bg-stone-50'
                      } text-lg font-bold leading-[24px]  flex flex-col justify-center items-center gap-16`}
                    >
                      {offerState ? item.offerInactive : item.offerActive}
                      {index === 0 && (
                        <Switch
                          label={
                            offerState ? 'Oferta inativa' : 'Oferta promocional'
                          }
                          active={offerState}
                          setActive={() => setOfferState(!offerState)}
                        />
                      )}
                    </div>
                    <div
                      className={`${getItemClassesBanks(
                        index,
                        listLength,
                      )} text-lg leading-[26px] justify-center flex items-center`}
                    >
                      {item.otherBanks}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <p className="py-24 px-16 md:px-24 lg:px-0 text-base leading-4 text-center">
          Média aproximada dos valores dos principais bancos do mercado. Confira
          no link todas as nossas{' '}
          <a
            onClick={() => {
              analytics?.track({
                event_type: 'cta stone',
                event_properties: {
                  name: 'cta stone',
                  description:
                    'Evento disparado quando o usuário clicar nos ctas da LP',
                  section_reference:
                    'Conheça as principais condições da Conta PJ Stone',
                  cta_reference: 'Taxas e Tarifas ',
                },
              });
            }}
            className="text-base leading-4 text-center text-stone-500 underline"
            href="https://res.cloudinary.com/dunz5zfpt/image/upload/v1707506704/stone-cms/prod/Tabela_de_Tarifas_e_Taxas_Retificacao_1_1178efdcb4.pdf?utm_source=google&utm_medium=search&utm_campaign=ac_gsn_brand_ID_6458670873&utm_content=stone&gclid=CjwKCAiA29auBhBxEiwAnKcSqjQobDdrEVXFIf7rF6YgFjA9zjrX8pDaGcmE6gFINIFtkBafCnb2uhoCz9EQAvD_BwE"
            target="_blank"
            rel="noreferrer"
          >
            taxas e tarifas
          </a>
        </p>
        <div className="hidden md:flex justify-center">
          <ButtonOpenYourAccount
            qrCodeLink={qrCodeLink}
            className="md:w-max"
            onClick={() =>
              analytics?.track({
                event_type: 'cta stone',
                event_properties: {
                  name: 'cta stone',
                  description:
                    'Evento disparado quando o usuário clicar nos ctas da LP',
                  section_reference:
                    'Tempo é dinheiro e com a conta stone você tem os dois ',
                  cta_reference: 'Abra sua conta PJ Stone',
                },
              })
            }
          >
            Abra sua Conta PJ Stone
          </ButtonOpenYourAccount>
        </div>
      </div>
    </>
  );
};

export default ComparativeList;
