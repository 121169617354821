'use client';

import { useResponsiveQuery } from 'atomic-layout';
import Image from 'next/image';
import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import ButtonBox from '@/components/ButtonBox';
import Icon from '@/components/v3/Icon';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { RtbFeaturesProps } from '@/types/cms/conta-pj';

interface RTB extends RtbFeaturesProps {
  qrCodeLink?: string;
  viewButton?: boolean;
  primaryButton?: number;
}

const RTB: React.FC<RTB> = ({
  referId,
  title,
  description,
  image,
  features,
  buttons,
  qrCodeLink = LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ,
  viewButton = true,
  primaryButton = 1,
}) => {
  const isTabletWeb = useResponsiveQuery({ from: 'md' });
  const isMobile = useResponsiveQuery({ to: 'sm' });

  const { analytics } = useContext(AmplitudeContext);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina',
          section_reference: title,
        },
      });
    }
  }, [analytics, inView, title]);

  return (
    <div
      id={referId}
      ref={ref}
      className="relative lg:h-[880px] md:min-h-[648px]"
    >
      <div
        className={`px-24 flex gap-24 lg:gap-[40px] items-center md:items-start md:justify-between m-auto lg:pb-[120px] lg:pt-[80px] lg:max-w-[1280px] py-[40px]  md:max-w-[768px] md:flex-row flex-col-reverse`}
      >
        <Image
          src={image.path}
          width={421}
          height={540}
          alt={image.alternativeText}
          className="flex justify-center items-center w-[298px] md:w-[326px] lg:w-[620px] lg:max-w-[421px] h-auto lg:max-h-[540px]"
        />
        <div className="flex items-center md:w-[349px] lg:w-[620px] h-[220px] sm:h-[132px] md:h-[330px] lg:h-[540px]">
          <div className="flex flex-col md:gap-16 lg:gap-24">
            <div>
              <h2 className="mb-8 font-display text-[28px] lg:text-[32px] font-bold leading-[36px] lg:leading-[40px]">
                {title}
              </h2>
              <p className="font-sharon text-[18px] lg:text-[20px] leading-[26px] lg:leading-5">
                {description}
              </p>
            </div>

            {isTabletWeb && (
              <div className="flex z-10 md:flex-col lg:flex-row gap-8 lg:gap-[16px] md:mb-24 w-[232px] lg:w-auto">
                {buttons.map((button, index) => (
                  <>
                    <ButtonBox
                      key={index}
                      qrCodeLink={qrCodeLink}
                      buttons={[button]}
                      section={title}
                    />
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex absolute top-[260px] sm:top-[220px] md:top-[180px] lg:top-[205px] flex-col gap-24 justify-center items-center py-16 pl-24 w-full h-full">
        <div className="flex overflow-x-auto gap-24 xl:justify-center pr-24 w-full">
          {features.map(({ title, description, icon }, index) => (
            <div key={index}>
              <div
                className={`flex flex-col md:w-[286px] lg:w-[302px] w-[257px] bg-display-100 border-display-100 rounded-md border-2 md:p-24 p-16`}
              >
                <div className="flex justify-center items-center mb-16 lg:mb-24 w-48 min-h-[48px] bg-stone-100 rounded-[8px]">
                  <Icon
                    name={icon.name}
                    className="w-24 h-24 text-stone-500 fill-current"
                  />
                </div>
                <h3 className="font-display text-lg lg:text-xl font-bold leading-[28px] lg:leading-7">
                  {title}
                </h3>
                <p className="md:max-w-[300px] lg:max-w-[330px] font-sharon text-[16px] lg:text-lg tracking-tight leading-[26px]">
                  {description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="pr-24 w-full">
          {isMobile && viewButton && (
            <ButtonBox
              qrCodeLink={qrCodeLink}
              buttons={[buttons[primaryButton]]}
              section={title}
            />
          )}
        </div>
      </div>
      <div className="flex md:hidden px-24 mt-[124px] sm:mt-[130px]"></div>
    </div>
  );
};

export default RTB;
