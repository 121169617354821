export const listprops = {
  referId: 'condicoes-conta-pj-stone',
  title: 'Conheça as principais condições da Conta PJ Stone',
  items: {
    header: [
      {
        nameList: 'Conta',
        nameListMobile: 'Conta',
      },
      {
        nameList: 'Emissão de Link de Pagamento',
        nameListMobile: 'Link',
        description: 'Para emissões de Link de Pagamento',
      },
      {
        nameList: 'Transferências e pagamentos via Pix',
        nameListMobile: 'Transf. via Pix',
      },
      {
        nameList: 'Emissão de Boleto',
        nameListMobile: 'Boleto',
        description: 'Para emissões de Boleto',
      },
    ],

    list: [
      {
        nameList: 'Conta PJ Stone',
        item: [
          {
            value: 'Grátis',
          },
          {
            value: 'Grátis',
          },
          {
            value: 'Grátis',
          },
          {
            value: 'Grátis',
          },
        ],
      },
      {
        nameList: 'Outros bancos',
        item: [
          {
            value: 'R$ 139,00/mês',
          },
          {
            value: 'R$ 69,90/mês',
          },
          {
            value: 'Grátis',
          },
          {
            value: 'R$ 8,00/mês',
          },
        ],
      },
    ],
  },
};
