'use client';

import { useResponsiveQuery } from 'atomic-layout';
import Link from 'next/link';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { FeaturesProps } from '@/types/cms';

import styles from './FixedTabs.module.css';
import MobileTabs from './MobileTabs';

interface FixedTabsProps extends FeaturesProps {
  children: ReactNode;
  HeightSectionPrimary: number;
}

const FixedTabs: React.FC<FixedTabsProps> = ({
  cards,
  children,
  HeightSectionPrimary,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeSections, setActiveSections] = useState<number>(0);
  const container = useRef<HTMLDivElement>(null);

  const isMobile = useResponsiveQuery({ to: 'md' });

  useEffect(() => {
    const handleScroll = () => {
      if (container.current) {
        const containerTop = container.current.offsetTop;
        const containerHeight = container.current.clientHeight;
        const scrollY = window.scrollY;

        const containerVisible =
          scrollY >= containerTop + (isMobile ? 300 : 100) &&
          scrollY <= containerTop + containerHeight;
        setIsVisible(containerVisible);

        const scrollTopWithinContainer =
          scrollY - (containerTop - HeightSectionPrimary);
        const progress = (scrollTopWithinContainer / containerHeight) * 100;
        setActiveSections(progress);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [HeightSectionPrimary, isMobile]);

  return (
    <div>
      {isMobile ? (
        <>
          <div
            className={`${
              isVisible ? 'flex' : 'hidden'
            } flex-col fixed top-0 z-[50] w-full`}
          >
            <MobileTabs cards={cards} />
            <progress
              className={styles['progress-bar']}
              style={{ width: '100%' }}
              value={activeSections}
              max="100"
            ></progress>
          </div>
          <div ref={container}>{children}</div>
        </>
      ) : (
        <div className="flex justify-center">
          <div
            className={`${
              isVisible ? 'flex' : 'hidden'
            } fixed justify-center items-center top-0 z-[50] w-full bg-display-0 flex-col px-24 2xl:px-0 2xl:w-[1280px] lg:gap-20 lg:pt-24 pt-16 gap-12`}
          >
            <div
              className={`${isVisible ? 'grid' : 'hidden'} grid-cols-3 w-full `}
            >
              {cards.map(({ title, link }, index) => (
                <Link
                  key={index}
                  href={{
                    pathname: link ? link.href : '',
                    hash: link ? link.hash : '',
                  }}
                  aria-label={`Link para a sessão ${title}`}
                >
                  <div className="h-auto cursor-pointer">
                    <p className="font-display text-[20px] lg:text-[24px] font-medium leading-[28px] lg:leading-6">
                      {title}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
            <progress
              className={styles['progress-bar']}
              style={{ width: '100%', height: '4px' }}
              value={activeSections}
              max="80"
            ></progress>
          </div>
          <div ref={container}>{children}</div>
        </div>
      )}
    </div>
  );
};

export default FixedTabs;
