import Icon from '@/components/v3/Icon';

import { TResourceFeatures } from './features';

const FeaturesNewOffer = ({
  features,
  background = '',
}: {
  features: TResourceFeatures[];
  background?: string;
}) => {
  return (
    <section
      className={`flex justify-center items-center p-24 py-40 w-full h-auto ${background}`}
    >
      <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-24 items-start w-full max-w-screen-xl h-full">
        {features.map(({ icon, title, subtitle, span }) => (
          <div className="flex gap-16 xl:w-[290px]">
            <div>
              <Icon
                name={icon}
                className="w-[64px] h-[64px] text-stone-50 fill-current"
              />
            </div>
            <div className="flex flex-col gap-4 justify-between h-full">
              <h6 className="font-display text-xl font-bold leading-5">
                {title}
              </h6>
              <p className="text-lg leading-[26px]">
                {subtitle}
                {span && <span className="font-bold">{span}</span>}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesNewOffer;
