'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { ImageCMSProps } from '@/types/cms';

import style from './AnimateImage.module.css';

type TAnimateImage = {
  interval: number;
  firstImage: ImageCMSProps;
  secundImage: ImageCMSProps;
};

const AnimateImage: React.FC<TAnimateImage> = ({
  interval,
  firstImage,
  secundImage,
}) => {
  const [showImage2, setShowImage2] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowImage2(prev => !prev);
    }, interval);

    return () => clearInterval(intervalId);
  }, [interval]);

  return (
    <div className="overflow-hidden relative max-h-[257px] md:max-h-[409px] lg:max-h-[495px]">
      <div
        className={classNames(style.FlipContainer, {
          [style.Container]: showImage2,
          [style.Flip]: showImage2,
        })}
      >
        <div
          className={classNames(
            `${style.Flipper} w-[312px] md:w-[494px] lg:w-[600px] h-[257px] md:h-[409px] lg:h-[495px]`,
            {
              [style.Flip]: showImage2,
            },
          )}
        >
          <div className={style.Front}>
            <Image
              src={firstImage.path}
              alt={firstImage.alternativeText}
              layout="fill"
            />
          </div>
          <div className={style.Back}>
            <Image
              src={secundImage.path}
              alt={secundImage.alternativeText}
              layout="fill"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimateImage;
