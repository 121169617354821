'use client';

import { useResponsiveQuery } from 'atomic-layout';
import Image from 'next/image';
import React, { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import ArrowCarousel from '@/components/ArrowCarousel';
import Icon from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';
import { BlogPostsCardProps } from '@/types/cms';
import { BlogPostsProps } from '@/types/cms/conta-pj';

const BlogCard: React.FC<BlogPostsCardProps> = ({
  tag,
  title,
  link,
  image,
}) => {
  const isTablet = useResponsiveQuery({ from: 'md' });

  return (
    <div className="flex flex-col justify-around p-16 lg:p-24 w-full h-full text-display-900 hover:text-stone-500 focus:text-stone-500 bg-white rounded-md focus:border-2 active:border-2 focus:border-stone-200 active:border-stone-200">
      <a
        href={link.href}
        target={link.target}
        rel="noreferrer noopener"
        className="cursor-pointer"
      >
        <div className="flex justify-center items-center py-2 px-8 mb-8 lg:mb-16 w-max h-24 lg:h-28 bg-stone-100 rounded-xxs">
          <p className="text-sm lg:text-base font-semibold leading-3 text-stone-600">
            {tag}
          </p>
        </div>
        <div className="flex gap-24 md:gap-8 mb-16 w-full lg:w-[260px] h-[120px] md:h-[130px]">
          <div>
            <h6 className="overflow-hidden font-display font-medium text-inherit">
              {title}
            </h6>
          </div>
          <div>
            <Icon
              name="arrow-right"
              className="w-24 h-24 text-inherit fill-current"
            />
          </div>
        </div>
        <div className="w-full">
          <Image
            src={image.path}
            width={isTablet ? '254' : '225'}
            height="170"
            className="rounded-md"
            alt={image.alternativeText}
          />
        </div>
      </a>
    </div>
  );
};

const BlogPosts: React.FC<BlogPostsProps> = ({
  referId,
  icon,
  title,
  description,
  blogPosts,
}) => {
  const { analytics } = useContext(AmplitudeContext);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina ',
          section_reference: 'Todas as novidades sobre a Conta no nosso Blog',
        },
      });
    }
  }, [analytics, inView]);
  return (
    <div
      className="flex flex-col py-40 lg:py-80 w-full bg-display-50"
      id={referId}
      ref={ref}
    >
      <div className="flex flex-col justify-center items-center px-24 mx-auto w-full md:max-w-[1280px]">
        <div className="flex w-full lg:max-w-[1304px]">
          <div className="flex flex-col lg:flex-row lg:gap-16 lg:items-center mb-24">
            <div>
              <Icon name={icon.name} />
              <div className="flex flex-col lg:flex-row lg:items-center">
                <h2 className="mt-24 font-display text-3xl lg:text-4xl font-bold leading-7 lg:leading-8">
                  {title}
                </h2>
                <div className="mt-8 lg:mt-24 max-w-[632px]">
                  <p className="text-lg lg:text-xl leading-[26px] lg:leading-[28px]">
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ArrowCarousel>
          {blogPosts.map((card, index) => (
            <div
              key={index}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="flex flex-col gap-24 !min-w-[230px] sm:!min-w-[257px] md:!min-w-[250px] lg:!min-w-[302px] h-full lg:h-[390px] keen-slider__slide"
              onClick={() =>
                analytics?.track({
                  event_type: 'cta stone',
                  event_properties: {
                    name: 'cta stone',
                    description:
                      'Evento disparado quando o usuário clicar nos ctas da LP',
                    section_reference: 'Blog',
                  },
                })
              }
            >
              <BlogCard {...card} />
            </div>
          ))}
        </ArrowCarousel>
      </div>
    </div>
  );
};

export default BlogPosts;
