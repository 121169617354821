interface StepHeadingProps {
  title: string;
  description?: string;
}

export const StepHeading: React.FC<StepHeadingProps> = ({
  title,
  description,
}: StepHeadingProps) => {
  return (
    <>
      <h2
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        className="lg:max-w-[450px] font-display text-[32px] lg:text-[40px] font-bold leading-[38px] lg:leading-[48px]"
      />
      {description && (
        <p className="mt-8 lg:mt-8 font-sharon text-[18px] leading-[26px] lg:leading-[28px] lg:paragraph-20">
          {description}
        </p>
      )}
    </>
  );
};
