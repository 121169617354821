'use client';

import React, { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import ModalVideo from '@/components/ModalVideo';
import ServiceVideoModal from '@/components/ServiceVideoModal/';
import ServiceVideo from '@/components/ServiceVideoModal/videoModal';
import Icon from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';
import {
  trackPartialReproduction,
  trackPlayVideo,
} from '@/helpers/trackPlayVideo';
import { ServiceVideoPjProps } from '@/types/cms/conta-pj';

export type Props = {
  thereAreOtherVideoPlaying?: boolean;
  shouldPlayInSequence?: boolean;
};

const ServiceVideoPj: React.FC<ServiceVideoPjProps> = ({
  referId,
  title,
  thumbnail,
  button,
  analyticsReference,
  videoUrl,
}) => {
  const [shouldPlayInSequence, setShouldPlayInSequence] = useState(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { analytics } = useContext(AmplitudeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina ',
          section_reference: analyticsReference,
        },
      });
    }
  }, [analytics, analyticsReference, inView]);

  useEffect(() => {
    showModal ? setShouldPlayInSequence(false) : setShouldPlayInSequence(true);
  }, [showModal]);

  useEffect(() => {
    const handleScroll = () => {
      if (showModal) {
        disableBodyScroll();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      enableBodyScroll();
    };
  }, [showModal]);

  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = 'auto';
  };

  return (
    <section id={referId} ref={ref} className="xl:flex justify-center">
      <div className="mx-[24px] mt-[40px] lg:mt-[80px] mb-[40px] xl:w-[1280px] max-w-[1280px]">
        <h2
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          className="mb-[24px] lg:mb-[40px] font-display text-[32px] lg:text-[40px] font-bold leading-[38px] lg:leading-[48px]"
        />
        <ServiceVideoModal
          classVideo="lg:h-[479px] md:h-[304px] rounded-lg bg-stone-600"
          colorButton={button.buttons.color}
          titleButton={button.buttons.label}
          shouldPlayInSequence={shouldPlayInSequence}
          setPlayVideoModal={setShowModal}
          thumbnail={{
            src: thumbnail.path,
            quality: 100,
            alt: thumbnail.alternativeText,
          }}
          srcVideo={videoUrl}
          iconButton={button.icon?.name || ''}
        />

        <ModalVideo
          open={showModal}
          closeOnClickOut
          onCloseClick={() => {
            setShowModal(false);
            enableBodyScroll();
          }}
          className="md:p-0 py-0 px-0 bg-transparent rounded-[0px]"
        >
          <div>
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className={`flex absolute top-[24px] right-[24px] justify-center items-center p-8 w-48 h-48 bg-display-900 bg-opacity-50 hover:bg-opacity-90 rounded-full `}
              aria-label="close"
            >
              <Icon
                name="close"
                className="w-32 h-32 text-display-0 fill-current"
              />
            </button>
            <ServiceVideo
              setIsShowingVideo={setShowModal}
              onClose={() => setShowModal(false)}
              className={`${'relative sm:max-w-[500px] md:max-w-[700px] lg:max-w-[993px]'}`}
              autoPlay={showModal}
              srcVideo={videoUrl}
              classButton="top-[-10px] right-[14px]"
              trackPlayVideo={() =>
                trackPlayVideo(
                  analytics,
                  'Vender de diversas formas, gerir sem perder tempo, e girar com o dinheiro certo na hora certa',
                  'Conheça a Conta Stone',
                )
              }
              trackPartialReproduction={(percentage: number) =>
                trackPartialReproduction(
                  analytics,
                  'Vender de diversas formas, gerir sem perder tempo, e girar com o dinheiro certo na hora certa',
                  'Conheça a Conta Stone',
                  percentage,
                )
              }
            />
          </div>
        </ModalVideo>
      </div>
    </section>
  );
};

export default ServiceVideoPj;
