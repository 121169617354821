/* eslint-disable import/no-extraneous-dependencies */

'use client';

import classNames from 'classnames';
import Link from 'next/link';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player/lazy';

/* eslint-disable import/no-extraneous-dependencies */
import Button, { ButtonProps } from '@/components/Button';
import { AmplitudeContext } from '@/contexts/amplitude';

import { iconName } from '../NewIcon/types';
import Icon from '../v3/Icon';
import styles from './styles.module.css';

export type Props = {
  isLCP?: boolean | undefined;
  srcVideo?: string;
  titleButton: string;
  children?: React.ReactNode;
  className?: string;
  setPlayVideoModal: (value: React.SetStateAction<boolean>) => void;
  shouldPlayInSequence: boolean;
  thumbnail?: {
    src: string;
    alt: string;
    quality?: number;
  };
  classVideo?: string;
  colorButton?: ButtonProps['color'];
  iconButton: string;
};

const ServiceVideoModal: React.FC<Props> = ({
  srcVideo,
  setPlayVideoModal,
  shouldPlayInSequence,
  titleButton,
  children,
  colorButton,
  classVideo,
  iconButton,
}) => {
  const storyRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<ReactPlayer>(null);
  const [isShowingVideo, setIsShowingVideo] = useState(true);
  const { analytics } = useContext(AmplitudeContext);
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const pauseVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.setState({ paused: true });
      setIsShowingVideo(false);
    }
  }, []);

  const playVideo = useCallback(async () => {
    if (videoRef.current) {
      videoRef.current.setState({ paused: false });
      setIsShowingVideo(true);
    }
  }, []);

  function handleClickPauseVideo() {
    analytics?.track({
      event_type: 'video played',
      event_properties: {
        name: 'video played',
        description: `Evento disparo quando o usuário clicar no CTA de play do video da segunda section`,
        section_reference:
          'Vender de diversas formas, gerir em perder tempo, e girar com o dinheiro certo na hora certa',
        cta_reference: 'Conheça a Conta Stone',
      },
    });
    setPlayVideoModal(true);
    return isShowingVideo ? pauseVideo() : playVideo();
  }

  useEffect(() => {
    async function handleExternalPlayVideoControl() {
      if (!shouldPlayInSequence) return;
      try {
        await playVideo();
      } catch (error) {}
    }

    handleExternalPlayVideoControl();
  }, [playVideo, shouldPlayInSequence]);

  return (
    <>
      <div ref={ref}>
        <article
          ref={storyRef}
          className={`${classVideo} overflow-hidden relative flex justify-center items-center`}
        >
          {inView && (
            <ReactPlayer
              ref={videoRef}
              url={srcVideo}
              width="100%"
              height="100%"
              playing={isShowingVideo}
              loop={true}
              muted={true}
              className={styles.containerVideo}
              controls={false}
            />
          )}

          <div
            className={classNames(
              `${srcVideo ? 'gap-24' : 'gap-40'}`,
              'flex absolute bottom-0 z-10 flex-col justify-center items-center p-16 w-full h-full',
            )}
          >
            {children}
            {srcVideo ? (
              <Button
                color={colorButton}
                onClick={handleClickPauseVideo}
                className="w-[fit-content] shadow-md"
              >
                <Icon name={iconButton as iconName} className="w-24 h-24" />
                <span className="pl-8">{titleButton}</span>
              </Button>
            ) : (
              <Link href={{ hash: '#nossos-clientes' }} legacyBehavior>
                <a>
                  <Icon name="arrowDown" className="w-24 h-24" />
                </a>
              </Link>
            )}
          </div>
        </article>
      </div>
    </>
  );
};

export default ServiceVideoModal;
