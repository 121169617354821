'use client';

import { useResponsiveQuery } from 'atomic-layout';
import classNames from 'classnames';
import Image from 'next/image';
import { useContext, useState } from 'react';

import ButtonOpenYourAccount from '@/components/ButtonOpenYourAccount';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';

import FixedButton from '../Hero/components/FixedButton';
import Cards, { TCard } from './components/Cards';
import HeroModal from './components/HeroModal';
import styles from './HeroNewOffer.module.css';

export const resourceCards: TCard[] = [
  {
    firstText: 'Boleto',
    mainText: 'Grátis',
    lastText: 'gerados e pagos',
    isFirstCard: true,
  },
  {
    firstText: 'Pix transferência',
    mainText: '0%',
    lastText: 'de taxa',
  },
  {
    firstText: 'Pix em lote',
    mainText: 'Grátis',
  },
];

const HeroNewOffer: React.FC = () => {
  const { analytics } = useContext(AmplitudeContext);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const isDesktop = useResponsiveQuery({ to: 'xl' });

  return (
    <>
      <section
        className={classNames(
          styles.Container,
          `flex justify-center p-24 lg:py-80 lg:px-24 w-full rounded-br-[60px] lg:rounded-br-10xl`,
        )}
      >
        <FixedButton />
        <div className="flex flex-col md:flex-row gap-24 xl:gap-40 justify-center md:justify-between items-center w-full max-w-screen-xl">
          <div className="flex flex-col justify-center w-full lg:max-w-[620px]">
            <h1 className="flex flex-col font-display text-5xl lg:text-7xl font-black leading-[44px] md:leading-[58px] text-center md:text-left text-display-0">
              Tempo é dinheiro.
              <span>
                {' '}
                E com a {isDesktop && <br />}
                <span className="px-16 lg:mr-24 w-[fit-content] leading-[58px] md:leading-[50px] lg:leading-10 text-display-0 rounded-[40px] border-[3px] border-stone-300 border-solid">
                  Conta PJ Stone
                </span>
              </span>{' '}
              você tem os dois
            </h1>
            <p className="mt-8 lg:mt-16 w-full font-normal text-xl leading-5 text-center md:text-left text-display-0">
              Grátis, com atendimento humano em até 5 segundos
            </p>
            <div className="mt-24 w-full">
              <div className="flex flex-col">
                <div className="flex flex-wrap gap-16 lg:gap-y-16 lg:w-[548px]">
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-16 w-full">
                    {resourceCards.map(card => (
                      <Cards {...card} />
                    ))}
                  </div>
                </div>
                <p className="mt-8 text-base text-center md:text-left text-display-0">
                  Oferta válida pra novos clientes.{' '}
                  <span
                    onClick={() => setShowModal(true)}
                    className="decoration-slice text-base text-stone-200 underline cursor-pointer"
                  >
                    Confira mais detalhes
                  </span>
                </p>
                <HeroModal showModal={showModal} closeModal={closeModal} />
                <div className="flex flex-col sm:flex-row order-1 md:order-2 justify-center md:justify-start items-center mt-24 md:mb-0">
                  <ButtonOpenYourAccount
                    qrCodeLink={LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ}
                    color="primary"
                    className="md:w-max text-display-900 bg-stone-300"
                    onClick={() =>
                      analytics?.track({
                        event_type: 'cta stone',
                        event_properties: {
                          name: 'cta stone',
                          description:
                            'Evento disparado quando o usuário clicar nos ctas da LP',
                          section_reference: 'Hero',
                          cta_reference: 'Abra sua conta PJ Stone',
                        },
                      })
                    }
                  >
                    Abra sua Conta PJ Stone
                  </ButtonOpenYourAccount>
                </div>
              </div>
            </div>
          </div>
          <div className="flex relative justify-center items-center w-full md:min-w-[381px] lg:max-w-[455px]">
            <div className="flex md:hidden overflow-hidden rounded-[16px]">
              <Image
                alt={
                  'Aplicativo Conta PJ Stone sendo usado em smartphone. Ao fundo o cartão Stone'
                }
                src={'/site-stone/lp-conta-pj/hero-conta-pj-mobile.png'}
                width={276}
                height={292}
              />
            </div>
            <div className="hidden md:flex lg:hidden overflow-hidden max-h-[406px]">
              <Image
                alt={
                  'Aplicativo Conta PJ Stone sendo usado em smartphone. Ao fundo o cartão Stone'
                }
                src={'/site-stone/lp-conta-pj/hero-conta-pj-tablet.png'}
                width={384}
                height={406}
              />
            </div>
            <div className="hidden lg:flex overflow-hidden">
              <Image
                alt={
                  'Aplicativo Conta PJ Stone sendo usado em smartphone. Ao fundo o cartão Stone'
                }
                src={'/site-stone/lp-conta-pj/hero-conta-pj.png'}
                width={455}
                height={481}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroNewOffer;
