import { Ampli } from '@/utils/amplitude';

export function trackPlayVideo(
  analytics: Ampli | null,
  section_reference: string,
  cta_reference: string,
) {
  analytics?.track({
    event_type: 'video played',
    event_properties: {
      name: 'video played',
      description:
        'Evento disparado quando um video for parcialmente visualizado',
      section_reference,
      cta_reference,
    },
  });
}

export function trackPartialReproduction(
  analytics: Ampli | null,
  section_reference: string,
  cta_reference: string,
  percentage: number,
) {
  analytics?.track({
    event_type: 'video view partial',
    event_properties: {
      name: 'video view partial',
      description:
        'Evento disparado quando um video for parcialmente visualizado',
      section_reference,
      cta_reference,
      percentage,
    },
  });
}
