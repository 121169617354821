'use client';

import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import Icon from '@/components/v3/Icon';
import { FeaturesProps } from '@/types/cms';

const MobileTabs: React.FC<Pick<FeaturesProps, 'cards'>> = ({ cards }) => {
  const [activeTab, setActiveTab] = useState<string>(cards[0].title ?? '');
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let visibleTitle: null | string | undefined = null;
      let maxVisiblePercentage = 0;

      cards.forEach(({ title, link }) => {
        const element = document.getElementById(String(link?.hash));
        if (element) {
          const rect = element.getBoundingClientRect();
          const visibleHeight =
            Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
          const visiblePercentage =
            (visibleHeight / element.clientHeight) * 100;

          if (visiblePercentage > maxVisiblePercentage) {
            maxVisiblePercentage = visiblePercentage;
            visibleTitle = title;
          }
        }
      });

      setActiveTab(String(visibleTitle));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [cards]);

  return (
    <div className="flex flex-col w-full bg-display-0">
      {!openDropdown && (
        <div
          className="flex justify-between items-center py-16 px-24 w-full cursor-pointer"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <h5 className="font-display text-lg font-medium">{activeTab}</h5>
          <Icon
            name={openDropdown ? 'chevron-right' : 'chevron-down'}
            className="w-24 h-24 text-stone-500 fill-current"
          />
        </div>
      )}
      <div
        className={`${
          openDropdown ? 'flex flex-col gap-24 py-16 px-24' : 'hidden'
        }`}
      >
        {cards.map(({ title, link }, index: number) => {
          return (
            <div
              key={index}
              onClick={() => {
                setActiveTab(String(title));
                setOpenDropdown(false);
              }}
            >
              <Link
                key={index}
                href={{
                  pathname: link ? link.href : '',
                  hash: link ? link.hash : '',
                }}
                aria-label={`Link para a sessão ${title}`}
              >
                <div
                  className={`flex h-auto items-center justify-between cursor-pointer`}
                >
                  <h5
                    className={`font-display text-lg font-medium leading-6 ${
                      activeTab === title && 'text-stone-500'
                    }`}
                  >
                    {title}
                  </h5>
                  {index === 0 && (
                    <Icon
                      name="chevron-up"
                      className="w-24 h-24 text-stone-500 fill-current"
                    />
                  )}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileTabs;
