'use client';

type SwitchProps = {
  label?: string;
  disabled?: boolean;
  active: boolean;
  setActive: (active: boolean) => void;
};

const Switch: React.FC<SwitchProps> = ({
  label,
  disabled,
  active,
  setActive,
}) => {
  return (
    <div
      className="flex flex-col gap-4 justify-center items-center"
      onClick={() => setActive(!active)}
    >
      <div
        className={`flex items-center p-2 w-[46px] h-[26px] opacity-100 bg-stone-500 rounded-lg ${
          disabled && 'opacity-20 cursor-not-allowed'
        }
        ${active ? 'justify-start bg-display-800 opacity-20' : 'justify-end'}
         `}
      >
        <div className="w-20 h-20 bg-white rounded-10xl opacity-100"></div>
      </div>
      {label && (
        <p className="w-full text-base text-display-900 whitespace-nowrap">
          {label}
        </p>
      )}
    </div>
  );
};

export default Switch;
