'use client';

import { useResponsiveQuery } from 'atomic-layout';
import { useKeenSlider } from 'keen-slider/react';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import Icon from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';
import { SolutionsFeaturesProps } from '@/types/cms/conta-pj';
import { useViewportWidth } from '@xstyled/styled-components';

import ProductsCard from './components/ProductsCard';
import styles from './MeetSolutions.module.css';

const MeetSolutions: React.FC<SolutionsFeaturesProps> = ({
  referId,
  title,
  features,
}) => {
  const [currentPosition, setCurrentPosition] = useState(0);

  const [keenSliderRef, keenSlider] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 3,
      spacing: 24,
    },
    initial: 0,
    mode: 'snap',
    renderMode: 'performance',
    loop: false,
    breakpoints: {
      '(max-width: 767px)': {
        slides: {
          perView: 2.4,
          spacing: 16,
        },
      },
      '(max-width: 685px)': {
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
      '(max-width: 573px)': {
        slides: {
          perView: 1.8,
          spacing: 16,
        },
      },
      '(max-width: 516px)': {
        slides: {
          perView: 1.6,
          spacing: 16,
        },
      },
      '(max-width: 482px)': {
        slides: {
          perView: 1.5,
          spacing: 16,
        },
      },
      '(max-width: 450px)': {
        slides: {
          perView: 1.3,
          spacing: 16,
        },
      },
      '(max-width: 375px)': {
        slides: {
          perView: 1.18,
          spacing: 16,
        },
      },
    },
    slideChanged(s) {
      setCurrentPosition(Math.round(s.track.details.rel));
    },
  });

  const width = useViewportWidth();
  const dontShowArrows = width <= 1400;

  const prevArrowDisable = currentPosition < 1;
  const nextArrowDisable =
    currentPosition === keenSlider.current?.track.details.maxIdx;

  const isWebTablet = useResponsiveQuery({ from: 'md' });

  const { analytics } = useContext(AmplitudeContext);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina',
          section_reference:
            'Conheça melhor as soluçoes que a stone tem pro seu negocio',
        },
      });
    }
  }, [analytics, inView]);

  const amplitudeEvent = (title: string) => {
    analytics?.track({
      event_type: 'cta stone products',
      event_properties: {
        name: 'cta stone products',
        description:
          'Evento disparado quando o usuário clicar nos ctas que o direcionam para outras Landing Pages de produto, a partir da section "conheça melhor as solucoes que a Stone tem pro seu negocio"',
        section_reference:
          'Conheça melhor as soluçoes que a stone tem pro seu negocio',
        cta_reference: `${title}`,
      },
    });
  };

  return (
    <div
      id={referId}
      ref={ref}
      className="flex flex-col md:items-center py-40 lg:py-80 px-24 pr-0 md:pr-24 bg-display-100"
    >
      <h2 className="pr-24 mb-24 lg:mb-40 font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-9 text-center">
        {title}
      </h2>

      <div
        className={`flex md:hidden flex-wrap gap-x-16 justify-center w-full max-w-[1280px] h-full`}
      >
        <div
          ref={keenSliderRef}
          className={`flex max-w-full keen-slider pr-24 ${styles.sliderContainer}`}
        >
          {features.map((item, index) => (
            <div
              key={index}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="flex flex-col gap-24 h-full keen-slider__slide"
              onClick={() => {
                amplitudeEvent(item.title as string);
                analytics?.track({
                  event_type: 'cta stone',
                  event_properties: {
                    name: 'cta stone',
                    description:
                      'Evento disparado quando o usuário clicar nos ctas da LP',
                    section_reference:
                      'Conheça melhor as soluçoes que a stone tem pro seu negocio',
                  },
                });
              }}
            >
              {item.link ? (
                <Link
                  href={{ pathname: item.link?.href, hash: item.link?.hash }}
                  prefetch={false}
                  className="h-full"
                >
                  <ProductsCard {...item} />
                </Link>
              ) : (
                <ProductsCard {...item} />
              )}
            </div>
          ))}
        </div>
        {dontShowArrows && keenSlider && (
          <div className="flex gap-16 justify-center mt-24 lg:mt-40 w-full">
            <button
              onClick={() => {
                keenSlider.current?.prev();
              }}
              className={`flex justify-center items-center w-40 h-40 bg-display-200 border-0 border-display-0 border-solid ${
                !prevArrowDisable &&
                'hover:bg-display-300 focus:border-2 active:border-2 focus:border-stone-200 active:border-stone-200'
              } rounded-xs`}
              disabled={prevArrowDisable}
            >
              <Icon
                name="chevron-left"
                className={`w-20 h-20 font-bold fill-current ${
                  prevArrowDisable
                    ? 'text-display-400'
                    : 'text-display-700 hover:text-display-800'
                }`}
              />
            </button>
            <button
              onClick={() => {
                keenSlider.current?.next();
              }}
              className={`flex justify-center items-center w-40 h-40 bg-display-200 border-0 border-display-0 border-solid ${
                !nextArrowDisable &&
                'hover:bg-display-300 focus:border-2 active:border-2 focus:border-stone-200 active:border-stone-200'
              } rounded-xs`}
              disabled={nextArrowDisable}
            >
              <Icon
                name="chevron-right"
                className={`w-20 h-20 font-bold fill-current ${
                  nextArrowDisable
                    ? 'text-display-400'
                    : 'text-display-700 hover:text-display-800'
                }`}
              />
            </button>
          </div>
        )}
      </div>
      {isWebTablet && (
        <div className="flex flex-wrap gap-24 justify-center max-w-[1280px]">
          {features.map((item, index) =>
            item.link ? (
              <Link
                href={{ pathname: item.link?.href, hash: item.link?.hash }}
                prefetch={false}
              >
                <ProductsCard
                  {...item}
                  key={index}
                  onClick={() => {
                    amplitudeEvent(item.title as string);
                    analytics?.track({
                      event_type: 'cta stone',
                      event_properties: {
                        name: 'cta stone',
                        description:
                          'Evento disparado quando o usuário clicar nos ctas da LP',
                        section_reference:
                          'Conheça melhor as soluçoes que a stone tem pro seu negocio',
                      },
                    });
                  }}
                />
              </Link>
            ) : (
              <ProductsCard
                {...item}
                key={index}
                onClick={() => {
                  amplitudeEvent(item.title as string);
                  analytics?.track({
                    event_type: 'cta stone',
                    event_properties: {
                      name: 'cta stone',
                      description:
                        'Evento disparado quando o usuário clicar nos ctas da LP',
                      section_reference:
                        'Conheça melhor as soluçoes que a stone tem pro seu negocio',
                    },
                  });
                }}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default MeetSolutions;
