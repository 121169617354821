'use client';

import styles from './StepByStep.module.css';
import { StepMobile } from './StepMobile';
import { StepTable } from './StepTablet';
import { StepCardProps } from './types';

export const StepCard: React.FC<StepCardProps> = ({
  labelTime,
  src,
  setCurrentTime,
  setStepNumber,
  stepNumber,
  setMockedClick,
  currentTime,
  step,
  mockedClick,
  videoRef,
  isTablet,
}) => {
  return (
    <ul className="flex flex-col gap-24 p-0">
      {labelTime.map(({ title, description, tag }, index) => (
        <li key={`step-${index}`} className={`flex flex-col w-full`}>
          <div
            onClick={() => {
              setStepNumber(index);
              setMockedClick(true);
            }}
            className={`cursor-pointer flex relative ${
              stepNumber === index ? styles.active : 'md:border-transparent'
            }`}
          >
            {isTablet ? (
              <StepTable
                stepNumber={stepNumber}
                index={index}
                title={title}
                description={description}
                mockedClick={mockedClick}
                currentTime={currentTime}
                step={step}
                tag={tag}
              />
            ) : (
              <StepMobile
                stepNumber={stepNumber}
                index={index}
                title={title}
                description={description}
                step={step}
                setCurrentTime={setCurrentTime}
                setStepNumber={setStepNumber}
                labelTime={labelTime}
                videoRef={videoRef}
                src={src}
                tag={tag}
              />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
