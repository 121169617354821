export type TCard = {
  firstText: string;
  mainText: string;
  lastText?: string;
  isFirstCard?: boolean;
  className?: string;
  lineThrough?: boolean;
  fontMainText?: boolean;
};
const valuesToStrike = ['1,22%', '3,59%', '12,97%'];
const Cards: React.FC<TCard> = ({
  firstText,
  mainText,
  lastText,
  isFirstCard,
  className,
  lineThrough,
  fontMainText,
}) => {
  return (
    <div
      className={`${
        isFirstCard && 'col-span-2 lg:col-span-1'
      } relative md:w-auto h-[128px] lg:h-[140px] ${
        className
          ? className
          : 'rounded lg:rounded-md border-[1px] lg:border-2 border-display-0 border-opacity-20'
      } `}
    >
      <div
        className={`absolute inset-0 bg-display-0 rounded lg:rounded-md ${
          className ? ' opacity-0' : ' opacity-5'
        }`}
      ></div>
      <div className="flex relative flex-col justify-center items-center lg:p-16 py-16 px-8 lg:px-[10px] h-[128px] lg:h-[140px]">
        <p className="text-base lg:text-lg font-semibold leading-4 lg:leading-[26px] text-display-0">
          {firstText
            .split(new RegExp(`(${valuesToStrike.join('|')})`))
            .map((part, index) =>
              valuesToStrike.includes(part) && lineThrough ? (
                <span key={index} className="line-through">
                  {part}
                </span>
              ) : (
                <span key={index}>{part}</span>
              ),
            )}
        </p>
        <h2
          className={`font-display  font-bold lg:font-black leading-9 lg:leading-10 text-stone-300 ${
            fontMainText ? 'lg:text-7xl text-5xl ' : 'text-6xl lg:text-7xl'
          }`}
        >
          {mainText}
        </h2>
        {lastText && (
          <p className="text-base lg:text-lg font-semibold leading-4 lg:leading-[26px] text-center text-display-0">
            {lastText}
          </p>
        )}
      </div>
    </div>
  );
};
export default Cards;
