'use client';

import ReactPlayer from 'react-player/lazy';

import { VideoPlayerProps } from './types';

const VideoPlayer = ({
  step,
  stepNumber,
  setCurrentTime,
  setStepNumber,
  videoRef,
  labelTime,
  src,
  className,
}: VideoPlayerProps) => {
  return (
    <div
      className={`flex justify-center items-center bg-display-100 rounded-[16px] ${className} p-24 pb-0 md:pb-24`}
    >
      <div className="flex overflow-hidden w-full lg:w-[400px] h-full md:h-full bg-display-100">
        <ReactPlayer
          ref={videoRef}
          loop={true}
          muted={true}
          playsinline={true}
          playing={true}
          controls={false}
          url={src}
          width="100%"
          height="100%"
          onEnded={() => {
            videoRef.current?.seekTo(0);
            setStepNumber(0);
            videoRef.current?.setState({ play: true });
          }}
          onProgress={state => {
            if (state.playedSeconds >= step.frameStop) {
              setStepNumber(step =>
                stepNumber < labelTime.length - 1 ? step + 1 : step,
              );
            }
            if (
              state.playedSeconds >= step.frameStart &&
              state.playedSeconds <= step.frameStop
            ) {
              setCurrentTime(state.playedSeconds);
            }
          }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
