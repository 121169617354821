'use client';

import { useResponsiveQuery } from 'atomic-layout';
import { useAnimation } from 'framer-motion';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';

import { AmplitudeContext } from '@/contexts/amplitude';
import { VideoStepsProps } from '@/types/cms';

import styles from './StepByStep.module.css';
import { StepCard } from './StepCard';
import { StepHeading } from './StepHeading';
import VideoPlayer from './videoPlayer';

const HowWorks: React.FC<VideoStepsProps> = ({
  referId,
  title,
  description,
  labelTime,
  videoUrl,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { analytics } = useContext(AmplitudeContext);

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina ',
          section_reference: 'Tenha toda sua gestao a um app de distancia',
        },
      });
    }
  }, [analytics, inView]);

  const videoRef = useRef<ReactPlayer>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [stepNumber, setStepNumber] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [mockedClick, setMockedClick] = useState(false);
  const step = useMemo(() => labelTime[stepNumber], [labelTime, stepNumber]);
  const isTablet = useResponsiveQuery({ from: 'md' });

  useEffect(() => {
    if (stepNumber === 2) {
      controls.start('show');
    } else {
      controls.start('hidden');
    }
  }, [stepNumber, controls]);

  useEffect(() => {
    if (videoRef.current && inView) {
      const currentTime = videoRef.current.getCurrentTime();
      const time = currentTime - step.frameStart;
      const isNextFrame = time > 0 && time < 1;

      if (!isNextFrame) {
        videoRef.current.seekTo(step.frameStart);
      }
    }
  }, [inView, step]);

  const playAndPauseVideo = useCallback(() => {
    if (videoRef.current) {
      if (!inView) {
        videoRef.current.setState({ play: true });
        videoRef.current.setState({ pause: true });
      }
    }
  }, [videoRef, inView]);

  useEffect(() => {
    const body = document.getElementById('__next');

    if (body) {
      body.addEventListener('touchstart', playAndPauseVideo);
      body.addEventListener('touchend', playAndPauseVideo);
      body.addEventListener('touchmove', playAndPauseVideo);
      body.addEventListener('click', playAndPauseVideo);
    }

    return () => {
      if (body) {
        body.removeEventListener('touchstart', playAndPauseVideo);
        body.addEventListener('touchend', playAndPauseVideo);
        body.addEventListener('touchmove', playAndPauseVideo);
        body.removeEventListener('click', playAndPauseVideo);
      }
    };
  }, [playAndPauseVideo]);

  useEffect(() => {
    if (scrollContainerRef.current && inView) {
      const activeStep = document.getElementsByClassName(
        styles.active,
      )[0] as HTMLDivElement;

      scrollContainerRef.current.scrollTo({
        left: activeStep.offsetLeft - 24,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [step, scrollContainerRef, inView]);

  return (
    <>
      <div
        ref={ref}
        id={referId}
        className="flex overflow-hidden flex-col gap-24 md:gap-24 px-24 md:px-24 pt-[80px] lg:pt-[100px] lg:pb-60 text-display-900 bg-display-0"
      >
        <div className="block lg:hidden">
          <StepHeading title={title} description={description} />
        </div>
        <div
          ref={scrollContainerRef}
          className="md:grid md:grid-cols-2 gap-24 lg:gap-[40px] md:m-auto max-w-[1280px]"
        >
          {inView && (
            <div className="flex flex-col gap-24 md:gap-36 lg:gap-[40px] md:justify-center w-full">
              <div className="hidden lg:block">
                <StepHeading title={title} description={description} />
              </div>
              <StepCard
                labelTime={labelTime}
                src={videoUrl}
                setCurrentTime={setCurrentTime}
                setStepNumber={setStepNumber}
                stepNumber={stepNumber}
                setMockedClick={setMockedClick}
                currentTime={currentTime}
                step={step}
                mockedClick={mockedClick}
                videoRef={videoRef}
                isTablet={isTablet}
              />
            </div>
          )}

          {isTablet && inView && (
            <VideoPlayer
              videoRef={videoRef}
              step={step}
              stepNumber={stepNumber}
              setCurrentTime={setCurrentTime}
              setStepNumber={setStepNumber}
              labelTime={labelTime}
              src={videoUrl}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HowWorks;
