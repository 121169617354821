import Icon from '@/components/v3/Icon';

import { StepMobileProps } from './types';
import VideoPlayer from './videoPlayer';

export const StepMobile: React.FC<StepMobileProps> = ({
  stepNumber,
  index,
  title,
  description,
  step,
  labelTime,
  setCurrentTime,
  setStepNumber,
  videoRef,
  src,
  tag,
}: StepMobileProps) => {
  return (
    <div className="flex md:hidden flex-col gap-16 justify-center w-full">
      <div>
        {tag && (
          <span className="block xl:inline py-[2px] px-[8px] w-[fit-content] text-[16px] font-medium leading-[20px] text-black bg-stone-200 rounded-[16px]">
            {tag}
          </span>
        )}
        <div className="flex gap-24">
          <h5
            className={`w-full font-medium font-display text-xl leading-[28px] flex items-center justify-between  ${
              stepNumber === index ? 'text-stone-500' : 'text-display-900'
            }`}
          >
            {title}
          </h5>
          <div>
            {stepNumber === index ? (
              <Icon name="minus" />
            ) : (
              <Icon name="plus" />
            )}
          </div>
        </div>
        <p
          className={`mt-4 font-sharon leading-[24px] paragraph-16 ${
            stepNumber === index ? 'block' : 'hidden'
          }`}
        >
          {description}
        </p>
      </div>
      {stepNumber === index && (
        <VideoPlayer
          step={step}
          stepNumber={stepNumber}
          setCurrentTime={setCurrentTime}
          setStepNumber={setStepNumber}
          labelTime={labelTime}
          videoRef={videoRef}
          src={src}
          className="pt-[21px] pr-[30px] pl-[29px] m-auto w-[312px] h-[312px]"
        />
      )}
    </div>
  );
};
