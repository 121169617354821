'use client';

import { useContext, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import ButtonBox from '@/components/ButtonBox';
import Icon from '@/components/v3/Icon';
import AnimateImage from '@/components/v4/AnimateImage';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { BannerCardProps } from '@/types/cms/conta-pj';

interface SellMultipleWaysProps extends BannerCardProps {
  setStepNumber: (value: React.SetStateAction<number>) => void;
  analyticsReference: string;
  qrCodeLink?: string;
}

const SellMultipleWays: React.FC<SellMultipleWaysProps> = ({
  referId,
  title,
  description,
  firstImage,
  secondImage,
  iconsDescriptions,
  buttons,
  setStepNumber,
  analyticsReference,
  qrCodeLink = LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ,
}) => {
  const { analytics } = useContext(AmplitudeContext);
  const [ref, inView] = useInView({
    threshold: 0.4,
    delay: 1.2,
    triggerOnce: true,
  });

  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container.current) {
      const containerTop = container.current.clientHeight;
      setStepNumber(containerTop);
    }
  }, [setStepNumber]);

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina ',
          section_reference: analyticsReference,
        },
      });
    }
  }, [analytics, analyticsReference, inView]);

  return (
    <section
      id={referId}
      ref={ref}
      className="flex justify-center px-24 pt-[40px] lg:pt-[100px] pb-0 lg:pb-[60px] w-full bg-display-0"
    >
      <div
        ref={container}
        className="flex flex-col lg:flex-row gap-24 justify-center lg:justify-between w-full max-w-screen-xl"
      >
        {inView && (
          <div className="flex relative lg:order-2 justify-center items-center w-full lg:max-w-[715px]">
            <div className="flex md:hidden overflow-hidden">
              <AnimateImage
                interval={4000}
                firstImage={firstImage.mobile}
                secundImage={secondImage.mobile}
              />
            </div>
            <div className="hidden md:flex lg:hidden overflow-hidden">
              <AnimateImage
                interval={4000}
                firstImage={firstImage.tablet}
                secundImage={secondImage.tablet}
              />
            </div>
            <div className="hidden lg:flex relative">
              <AnimateImage
                interval={4000}
                firstImage={firstImage.web}
                secundImage={secondImage.web}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col w-full lg:min-w-[541px] lg:max-w-[541px]">
          <h2
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className="w-full md:w-[550px] lg:w-full font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-9"
          />
          <p className="mt-8 lg:mt-16 w-full font-normal text-lg lg:text-xl leading-[26px] lg:leading-5 text-left">
            {description}
          </p>
          <div className="flex flex-col gap-24 lg:my-24 mt-24 w-full">
            <div className="flex flex-col gap-16">
              {iconsDescriptions.map(
                ({ icon, description, subDescription, tag }, index) => (
                  <div className="flex gap-16 w-full" key={index}>
                    <Icon
                      name={icon.name}
                      className="w-[24px] h-[24px] text-stone-500 fill-current"
                    />
                    <div className="flex flex-col gap-4">
                      <h2 className="font-display text-xl lg:text-2xl font-medium leading-5 lg:leading-6">
                        {description}{' '}
                        {tag && (
                          <span className="py-4 px-8 text-[16px] font-semibold bg-stone-200 rounded-md">
                            {tag}
                          </span>
                        )}
                      </h2>

                      <p className="lg:text-lg text-left paragraph-16">
                        {subDescription}
                      </p>
                    </div>
                  </div>
                ),
              )}
            </div>
            {buttons && (
              <div className="hidden md:flex">
                <ButtonBox
                  qrCodeLink={qrCodeLink}
                  buttons={buttons}
                  section="Venda de multiplas formas com sua conta PJ"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SellMultipleWays;
