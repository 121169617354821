'use client';

import { useKeenSlider } from 'keen-slider/react';
import { useState } from 'react';

import Icon from '@/components/v3/Icon/index';
import { useViewportWidth } from '@xstyled/styled-components';

import styles from './index.module.css';

const ArrowCarousel = ({ children }: { children: React.ReactNode }) => {
  const [currentPosition, setCurrentPosition] = useState(0);

  const [keenSliderRef, keenSlider] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 3,
      spacing: 24,
    },
    initial: 0,
    mode: 'snap',
    renderMode: 'performance',
    loop: false,
    breakpoints: {
      '(max-width: 1024px)': {
        slides: {
          perView: 3,
          spacing: 24,
        },
      },
      '(max-width: 800px)': {
        slides: {
          perView: 2.2,
          spacing: 24,
        },
      },

      '(max-width: 760px)': {
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
      '(max-width: 620px)': {
        slides: {
          perView: 1.6,
          spacing: 16,
        },
      },
      '(max-width: 450px)': {
        slides: {
          perView: 1.4,
          spacing: 16,
        },
      },
      '(max-width: 360px)': {
        slides: {
          perView: 1.18,
          spacing: 24,
        },
      },
    },
    slideChanged(s) {
      setCurrentPosition(Math.round(s.track.details.rel));
    },
  });

  const width = useViewportWidth();
  const dontShowArrows = width <= 1400;

  const prevArrowDisable = currentPosition < 1;
  const nextArrowDisable =
    currentPosition === keenSlider.current?.track.details.maxIdx;

  return (
    <div
      className={`flex flex-wrap gap-x-16 justify-center w-full max-w-[1280px] h-full`}
    >
      <div
        ref={keenSliderRef}
        className={`flex max-w-full keen-slider pr-24 ${styles.sliderContainer}`}
      >
        {children}
      </div>
      {dontShowArrows && keenSlider && (
        <div className="flex gap-16 justify-center mt-24 lg:mt-40 w-full">
          <button
            onClick={() => {
              keenSlider.current?.prev();
            }}
            className={`flex justify-center items-center w-40 h-40 bg-display-200 border-0 border-display-0 border-solid ${
              !prevArrowDisable &&
              'hover:bg-display-300 focus:border-2 active:border-2 focus:border-stone-200 active:border-stone-200'
            } rounded-xs`}
            disabled={prevArrowDisable}
          >
            <Icon
              name="chevron-left"
              className={`w-20 h-20 font-bold fill-current ${
                prevArrowDisable
                  ? 'text-display-400'
                  : 'text-display-700 hover:text-display-800'
              }`}
            />
          </button>
          <button
            onClick={() => {
              keenSlider.current?.next();
            }}
            className={`flex justify-center items-center w-40 h-40 bg-display-200 border-0 border-display-0 border-solid ${
              !nextArrowDisable &&
              'hover:bg-display-300 focus:border-2 active:border-2 focus:border-stone-200 active:border-stone-200'
            } rounded-xs`}
            disabled={nextArrowDisable}
          >
            <Icon
              name="chevron-right"
              className={`w-20 h-20 font-bold fill-current ${
                nextArrowDisable
                  ? 'text-display-400'
                  : 'text-display-700 hover:text-display-800'
              }`}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default ArrowCarousel;
