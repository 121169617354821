'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import ButtonBox from '@/components/ButtonBox';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { SectionGirarProps } from '@/types/cms/conta-pj';

import styles from './SectionRotate.module.css';

interface SectionGirar extends SectionGirarProps {
  qrCodeLink?: string;
}

const SectionRotate: React.FC<SectionGirar> = ({
  referId,
  title,
  description,
  buttons,
  cards,
  analyticsReference,
  qrCodeLink = LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ,
}) => {
  const { analytics } = useContext(AmplitudeContext);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina ',
          section_reference: analyticsReference,
        },
      });
    }
  }, [analytics, analyticsReference, inView]);

  return (
    <div
      id={referId}
      className="pt-[80px] lg:pt-[100px] pb-[40px] md:pb-[80px] mx-24"
      ref={ref}
    >
      <div className="flex flex-col gap-24 lg:gap-[40px] m-auto max-w-[1280px]">
        <div className="lg:w-[620px]">
          <div className="flex flex-col gap-8">
            <h2
              dangerouslySetInnerHTML={{
                __html: title,
              }}
              className="sm:w-[500px] md:w-[620px] font-display text-4xl lg:text-6xl font-bold leading-[38px] md:leading-[48px]"
            />
            <p className="md:mb-[16px] lg:mb-24 lg:w-[538px] font-sharon text-[18px] lg:text-[20px] leading-[26px] lg:leading-[28px]">
              {description}
            </p>
          </div>
          <div className="hidden md:flex flex-col md:flex-row gap-16 justify-center items-start w-full md:w-max">
            <ButtonBox
              qrCodeLink={qrCodeLink}
              buttons={buttons}
              section="Venda de multiplas formas com sua conta PJ"
            />
          </div>
        </div>
        <div className="flex lg:grid flex-wrap lg:grid-cols-2 gap-24 justify-center items-center">
          {cards.map(({ tag, title, description, images }, index) => (
            <div
              className={classNames(
                styles.Gradient,
                `${
                  index === 0
                    ? 'lg:row-span-2 lg:max-h-[696px] justify-center items-center md:h-[394px] lg:h-full'
                    : 'lg:flex-row-reverse md:h-[394px] lg:h-[336px] lg:pr-0  justify-center items-center'
                } flex flex-col w-[312px] lg:w-full max-h-[394px] justify-end items-end border-2 lg:items-center rounded-md p-24 lg:p-40 gap-24 lg:gap-16 border-[#EFF4F8] `,
              )}
            >
              <div
                className={`hidden lg:flex ${
                  index !== 0
                    ? 'lg:h-[310px] lg:max-w-[300px]'
                    : 'h-[496px] justify-center items-center self-center'
                }`}
              >
                <div className="self-center w-full">
                  <Image
                    src={images.web.path}
                    alt={images.web.alternativeText}
                    width={index === 0 ? 461 : 300}
                    height={index === 0 ? 424 : 310}
                    className="object-contain"
                  />
                </div>
              </div>
              <div className={`hidden md:flex lg:hidden relative w-full`}>
                <Image
                  src={images.tablet.path}
                  alt={images.tablet.alternativeText}
                  width={260}
                  height={images.tablet.height}
                  className="object-contain h-auto"
                />
              </div>
              <div className={`flex md:hidden relative w-full`}>
                <Image
                  src={images.mobile.path}
                  alt={images.mobile.alternativeText}
                  width={260}
                  height={images.mobile.height}
                  className="object-contain h-auto"
                />
              </div>
              <div className="lg:flex lg:flex-col gap-8 lg:gap-4 lg:justify-end w-full lg:h-full">
                {tag && (
                  <div className="flex justify-center items-center py-4 px-8 w-max bg-stone-200 rounded-[16px]">
                    <p className="font-sans text-sm lg:text-base font-semibold leading-3 lg:leading-4 lg:paragraph-16 paragraph-14">
                      {tag}
                    </p>
                  </div>
                )}
                <h5 className="font-display text-[20px] lg:text-[32px] font-bold leading-[28px] lg:leading-[40px]">
                  {title}
                </h5>
                <p className="max-w-[350px] lg:font-sans text-[18px] lg:text-[20px] leading-[26px] lg:leading-[30px]">
                  {description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SectionRotate;
