'use client';

import Modal from '@/components/v3/Modal';

type THeroModal = {
  showModal: boolean;
  closeModal: () => void;
};

const HeroModal: React.FC<THeroModal> = ({ showModal, closeModal }) => {
  return (
    <>
      <Modal
        open={showModal}
        onCloseClick={() => closeModal()}
        data-testid="hero-modal"
        className="gap-24 items-center sm:items-start !p-24 lg:!p-40 max-w-[512px] lg:max-w-[624px] h-auto"
      >
        <div className="flex flex-col gap-24 max-w-[624px]">
          <div className="flex gap-40 justify-between items-start">
            <h5 className="font-display font-bold">
              Ativar a oferta da Conta PJ Stone é simples!
            </h5>
            <button
              type="button"
              onClick={() => closeModal()}
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className='after:inline-block after:scale-[1.5] cursor-pointer after:content-["\00d7"]'
            ></button>
          </div>
          <div className="flex flex-col gap-16">
            <p>
              É necessário que você cadastre uma chave pix (neste caso não pode
              ser utilizada a chave aleatória).{' '}
            </p>

            <p>Assim que ativada a oferta seus benefícios são:</p>

            <ul className="font-semibold leading-4">
              <li>&#8226; Três meses de boletos gerados e pagos gratuitos;</li>
              <li>&#8226; Pix por transferência grátis;</li>
              <li>&#8226; Pix em lote gratuitos.</li>
            </ul>

            <p>
              No caso de clientes que não fizerem o cadastro da chave pix,
              Boletos pagos serão cobrados no valor de R$ 1,99.{' '}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HeroModal;
