export const getItemClasses = (index: number, totalItems: number) => {
  const isFirstItem = index === 0;
  const isLastItem = index === totalItems - 1;
  const isSecondItem = index === 1;
  const isSecondLastItem = index === totalItems - 2;

  return `sticky left-0 z-10 p-16 bg-white  ${
    isSecondItem ? 'rounded-tl-2xl  border-t' : ''
  }
  ${isSecondLastItem ? 'rounded-bl-2xl' : ''}            
  ${isFirstItem || isLastItem ? 'bg-white' : 'border-b border-l'}`;
};

export const getItemClassesActive = (index: number, totalItems: number) => {
  const isFirstItem = index === 0;

  return `p-16 text-center  border-r-2 border-l-2 border-[#00A868]    
  ${isFirstItem ? 'rounded-t-2xl border-t-2 whitespace-nowrap' : ''}
   ${
     index === totalItems - 1
       ? 'rounded-b-2xl border-b-2 border-[#00A868]'
       : 'border-b-[#D5DEE8] border-b'
   }
`;
};

export const getItemClassesBanks = (index: number, totalItems: number) => {
  return `p-16 text-center  ${
    index === 0 || index === totalItems - 1
      ? 'bg-white'
      : 'bg-display-50 border-b border-r'
  }           
${index === 1 ? 'rounded-tr-2xl  border-t' : ''}
${index === totalItems - 2 ? 'rounded-br-2xl' : ''}
                  
`;
};
