/* eslint-disable import/no-extraneous-dependencies */

'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import styles from './styles.module.css';

interface VideoProps {
  className?: string;
  autoPlay?: boolean;
  srcVideo?: string;
  classVideo?: string;
  classButton?: string;
  onClose: () => void;
  setIsShowingVideo: (value: React.SetStateAction<boolean>) => void;
  trackPartialReproduction?: (percentage: number) => void;
  trackPlayVideo?: () => void;
}
const ServiceVideo: React.FC<VideoProps> = ({
  autoPlay,
  srcVideo,
  setIsShowingVideo,
}) => {
  const videoRef = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState(false);

  const pauseVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.setState({ paused: true, volume: 0 });
      setIsShowingVideo(false);
      setPlaying(false);
    }
  }, [setIsShowingVideo]);

  const playVideo = useCallback(async () => {
    if (videoRef.current) {
      videoRef.current.setState({ play: true, volume: 0.4 });
      setPlaying(true);
    }
  }, [videoRef]);

  useEffect(() => {
    autoPlay ? playVideo() : pauseVideo();
  }, [autoPlay, playVideo, pauseVideo]);

  return (
    <article className="flex overflow-hidden !relative justify-center items-center w-[360px] sm:w-[500px] md:w-[700px] lg:w-[993px] h-[202px] sm:h-[281px] md:h-[394px] lg:h-[558px]">
      <ReactPlayer
        ref={videoRef}
        url={srcVideo}
        width="100%"
        height="100%"
        className={`${styles.contentVideo}`}
        playing={playing}
        controls={false}
      />
    </article>
  );
};

export default ServiceVideo;
