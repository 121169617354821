'use client';

import Icon from '@/components/v3/Icon';
import { CardInfoProps } from '@/types/cms';

const ProductsCard: React.FC<CardInfoProps> = ({
  tag,
  title,
  description,
  icon,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col p-24 lg:p-40 md:w-[348px] lg:w-[410px] h-full md:h-[204px] lg:h-[248px] text-display-900 hover:text-stone-500 focus:text-stone-500 bg-display-0 rounded-md border-2 focus:border-2 active:border-2 border-display-200 focus:border-stone-200 active:border-stone-200 cursor-pointer"
    >
      <div className="flex justify-center items-center mb-24 w-48 min-h-[48px] bg-stone-50 rounded-[8px]">
        <div>
          <Icon
            name={icon.name}
            className="w-24 h-24 text-stone-500 fill-current"
          />
        </div>
      </div>

      <div className="flex gap-8 items-center">
        <h3 className="font-display text-xl lg:text-2xl font-bold leading-[28px] lg:leading-7 text-inherit">
          {title}
        </h3>
        {tag && (
          <div className="flex justify-center items-center py-4 px-8 w-max bg-stone-200 rounded-[16px]">
            <p className="text-sm lg:text-[18px] font-semibold">{tag}</p>
          </div>
        )}
        <div className="flex justify-center items-center">
          <Icon name="arrow-right" className="w-24 h-24 fill-current" />
        </div>
      </div>
      <p className="mt-4 lg:mt-8 md:w-full md:max-w-[300px] lg:max-w-[330px] font-sharon text-lg lg:text-xl leading-[26px]">
        {description}
      </p>
    </div>
  );
};

export default ProductsCard;
